import React from 'react'
export default function ({data}) {
  const {
    selectedAmount,
    note,
    buyerName,
    recipientName,
    buyerEmail,
    recipientEmail
  } = data
  return(

    <svg height="240px" width="310px" viewBox="0 0 505.9 312.7">
    <defs>
      <path
        id="prefix__a"
        d="M489.4 309H16.7c-6.6 0-12-5.4-12-12V16.5c0-6.6 5.4-12 12-12h472.6c6.6 0 12 5.4 12 12V297c.1 6.6-5.3 12-11.9 12z"
      />
    </defs>
    <clipPath id="prefix__b">
      <use xlinkHref="#prefix__a" overflow="visible" />
    </clipPath>
    <g clipPath="url(#prefix__b)">
      <image
        overflow="visible"
        width={1024}
        height={618}
        xlinkHref="https://d2abh92eaqfsaa.cloudfront.net/image/upload/q_82,w_400/v1551736184/danstuff/pablitos-tacos-cut.png"
        transform="scale(.5)"
      />
    </g>
    <text
      transform="translate(386.541 284.617)"
      fontFamily="'clarendon-text-pro'"
      fontSize={42.649}
    >
      {`$${selectedAmount}`}
    </text>
  </svg>
  )
}
