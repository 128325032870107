
import * as Components from "./Pablitostacos"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "pablitostacos"
}

