import React from 'react'
import {Link} from 'react-router-dom'
import './FormFranchise.scss'


const data = [
  { name: "First Name" },
  { name: "Last Name" },
  { name: "Email" },
  { name: "Phone" },
  // { name: "Address" },
  { name: "Territory of Interest" },
  // { name: "Number of Stores to Develop" },
  // { name: "Net Worth" },
  // { name: "Liquid Cash Available for Investment" },
  { name: "Where did you hear about us?" },
  // { name: "Do you currently own and operate restaurants?" },
  // { name: "If yes, how many restaurants?" },
  // { name: "Describe Restaurant Experience - Brands owned and Number of Locations", type: 'textarea' },
]

export default function ({ hide }) {
  const hideClass = hide ? " hide-form" : ""
  return(
    <div className={"form-wrapper-contact" + hideClass} >
      <form name="franchise" method="POST" data-netlify="true">
        {/* <h2>JOIN<br/>Pablito's List</h2> */}

        {/* <p>Perks & Location Updates</p> */}
        {/* <p>Sign up to be notified when we open</p> */}

        

        <input type="hidden" name="form-name" value="franchise" />

        {data.map(({name, type}) => {
          const id = `${name.replace(/\s+/g, '').toLowerCase()}`
          return(
            <p>
              <label htmlFor={id}>
                <span className="label">{name}</span>
                
                {type === 'textarea' ?
                <textarea name={id} id={id} type="text" /> :
                <input name={id} id={id} type="text" /> }
              </label>
            </p>
          )
        })}

      
        <p>
          <button type="submit">Submit</button>
        </p>
      </form>
    </div>
  )
}
