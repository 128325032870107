
import React, { Component } from 'react';
import './about.scss'

import CateringForm from './CateringForm'

export default function () {
  console.log('our story rendering')
  return(
    <div className="about-page">
      <article className="post our-story">
        <div className="header">
          <h1 className="about-page-title">Pablito's Catering</h1>

          <p>Catering for all types of event! Shoot us an email or submit the form below.</p>
        </div>

        <div className="content">
          <h2>Email</h2>
          <p>
            Catering: <a href="mailto:catering@pablitostacos.com">catering@pablitostacos.com</a>
          </p>

          {/* <>
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/935956192?h=9aac1f13c0"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
              />
            </div>
          
          </> */}


          <h2>Catering Form</h2>

          <CateringForm></CateringForm>
         

        </div>
      </article>




    </div>
  )
}

function AboutUs () {
  return(
    <div className="about-us">
      <p>We are Pablito's Tacos, the hottest brand of tacos in Los Angles. What started as a small set up in a parking lot with 3 employees, has grown to over 25 employees, 2 trucks and 3 stands in just under a year. It was started by Danny Rodriguez and Chef Florencia "Flor" Oropeza in December 2018 out of the parking lot of Pablito's Kitchen. Flor who was the head chef of </p>
    </div>
  )
}



const pressLinks = [
  { publication: `Grubhub`,
  text: `GRUBHUB CHOOSES PABLITOS FOR NATIONWIDE CAMPAIGN`,
  url: "https://www.instagram.com/tv/CQjPxb2j603/"},

{ publication: `Eater`,
  text: `One of the Valley’s Most Famous Taco Spots Just Opened Big in Chinatown`,
  url: "https://la.eater.com/2022/5/18/23076643/am-intel-morning-news-los-angeles-pablitos-tacos-chinatown-world-food-court-opening"},
{ publication: `LA Times`,
  text: `This restaurant has a taco for every diet (restriction)`,
  url: "https://www.latimes.com/food/story/2022-08-29/keto-tacos-wedge-salad-nancy-silverton-jerusalem-chicken"},
{ publication: `First We Feast`,
  text: `Reggie Watts Beatboxes While Eating Peruvian-Style Tacos`,
  url: "https://www.youtube.com/watch?v=c7XfpPqKvzU"},
{ publication: `Instagram @foodbeast`,
  text: `Trying the Chile Relleno burrito`,
  url: "https://www.instagram.com/reel/CibN6e2pjYm/?igshid=YmMyMTA2M2Y%3D"},
{ publication: `ABC Eyewitness`,
  text: `Burbank's Pablito's Tacos adds a Peruvian taste to tacos that locals love`,
  url: "https://abc7.com/pablitos-tacos-burbank-food-peruvian-inspired/11769666/"},
{ publication: `Instagram @thejoe`,
  text: `Testing Pablito's tacos`,
  url: "https://www.instagram.com/reel/CjqcjyIpS-f/?igshid=YmMyMTA2M2Y%3D"},
{ publication: `Instagram @fluffcups`,
  text: `REVIEW @pablitostacos`,
  url: "https://www.instagram.com/reel/CnpP_Jejmhy/?igshid=YmMyMTA2M2Y%3D"},
{ publication: `LA Opinion`,
  text: `Tacos con fusión de sabor peruano y mexicano se vuelven un ‘hit’ entre los comensales`,
  url: "https://laopinion.com/2021/06/06/tacos-con-fusion-de-sabor-peruano-y-mexicano-se-vuelven-un-hit-entre-los-comensales/"},
{ publication: `Instagram @eatamerrica`,
  text: `PERFECT TACO SPOT ALERT!!!`,
  url: "https://www.instagram.com/p/CXwfdWMvEIq/?img_index=1"},

{ publication: `Eater`,
  text: `10 of LA’s Best Tijuana-Style Taco Spots, Mapped`,
  url: "https://la.eater.com/maps/tijuana-style-tacos-los-angeles-carne-asada-tortillas-adobada"},
{ publication: `CBS News`,
  text: `Major grocery chains rationing meat purchases to curb hoarding`,
  url: "https://www.cbsnews.com/news/meat-shortages-rationing-kroger-costco-grocery-chains-supermarkets/"},

{ publication: `L.A. Taco`,
  text: `THE L.A. TACO GUIDE TO THE 20 BEST AL PASTOR AND ADOBADA TACOS IN LOS ANGELES, MAPPED`,
  url: "https://www.lataco.com/best-al-pastor-adobada-la/"},

{ publication: `MSN hoodline`,
  text: `Pablito's Tacos brings tacos and more to North Hollywood`,
  url: "https://www.msn.com/en-us/news/us/pablitos-tacos-brings-tacos-and-more-to-north-hollywood/ar-BBV6V1p"},

{ publication: `Los Angeles Times`,
  text: `This new taco stand in Burbank is making Peruvian lomo saltado tacos`,
  url: "https://www.latimes.com/food/dailydish/la-fo-re-pablitos-20181227-story.html"},

{ publication: `Food&Wine`,
  text: `L.A.’s Summer of Tacos Can’t Stop, Won’t Stop`,
  url: "https://www.foodandwine.com/travel/restaurants/los-angles-taco-summer"},

{ publication: `L.A. Taco`,
  text: `FINALLY, THE L.A. TACO GUIDE TO THE BEST STREET TACOS IN THE SAN FERNANDO VALLEY`,
  url: "https://www.lataco.com/best-tacos-in-the-valley-2019/"},


{ publication: `Los Angeles Times`,
  text: `Newsletter: There’s no better place than Los Angeles to live the taco life`,
  url: "https://www.latimes.com/food/story/2019-10-12/los-originales-tacos-arabes-de-puebla-tasting-notes-newsletter-patricia-escarcega"},

  { publication: `@lafoodjunkie`,
    text: `EPIC QUESADILLA @pablitostacos 🔥🔥 this new PERUVIAN/TJ style taco stand is changing the game a bit🙌💯 @mrbiggsmenu #lafoodjunkie`,
    url: "https://www.instagram.com/lafoodjunkie/p/BtP4seWFPP4/"},

  { publication: `@pablitostacos`,
    text: `🚨NEW ITEM ALERT!!! 🤤 OUR LOMO SALTADO BURRITO🤤 ONLY AVAILABLE AT THE HOLLYWOOD LOCATION!!🚨.`,
    url: "https://instagram.com/p/Bwuue4hFK6N/"},

  { publication: `@latimesfood`,
    text: `We don’t clown around when it comes to #tacos. 🤡🌮🌮🌮`,
    url: "https://www.instagram.com/p/BzeXMrAA65y/?igshid=gyf6ey8jy38r"},

  { publication: `@goodeatzco`,
    text: `What do you do when it’s a little chilly outside? Dive into some huge extra CHEESY CARNE ASADA QUESADILLAS from @pablitostacos 🤤🧀🔥. Not to worry, the guac makes it healthy🤘🏻🥑
  🍴: (Carne Asada Quesadillas with Guac and Carne Asada Tacos ).`,
    url: "https://www.instagram.com/p/BtwPAiyhmUS/?igshid=ebsqupjkahkz"},

  { publication: `@mrbiggsmenu`,
    text: `EPIC QUESADILLA @pablitostacos 🔥🔥 this new PERUVIAN/TJ style taco stand is changing the game a bit🙌💯`,
    url: "https://www.instagram.com/p/Bs4jc7cgxRy/?igshid=t82rj4kt1y"},

  { publication: `@foodiemobbb`,
    text: `Would you try Asada Fries 🍟 Pablitos Style?!? (Added Chorizo) 🧐🧐🧐 If yes then you can get some from @pablitostacos !!! `,
    url: "https://www.instagram.com/p/By32G6-Fcga/?igshid=468h41zpf7ge"},

]
