export default function Vote () {

    return(
        <div 
        style={{
            textAlign: "center",
            lineHeight: "1.5em"
        }}
        className="vote">
        <h2
        style={{
          margin: "1em 0",
          fontWeight: 800,
          fontSize: "2em",
        }}
        >Vote for Pablito's Tacos</h2>

<p>Please take a few seconds to vote for us in Round 2 of Taco Madness 2023</p>

<p>Thank you all who helped us win the first round!</p>
        <iframe src="https://poll.app.do/embed/taco-madness-2023-round-2" 
                title="Taco Madness 2023: Round 2" 
                frameborder="0" 
                loading="lazy" 
                style={{"display":"block","width":"100%","height":"3171px","maxWidth":"810px","margin":"0px auto","border":"3px solid rgb(255, 0, 0)","borderRadius":"6px","overflow":"hidden"}}
                id="iFrameResizer0" 
                scrolling="no"></iframe>
      </div>
    )
}