
import React, { Component } from 'react';
import './about.scss'

import FormFranchise from './FormFranchise'

export default function () {
  console.log('our story rendering')
  return(
    <div className="about-page">
      <article className="post our-story">
        <div className="header">
          <h1 className="about-page-title">Join Pablito's Team!!</h1>
        </div>

        <div className="content">
          <h2>Contact</h2>
          <p>
            Danny Rodriguez: <a href="mailto:danny@pablitostacos.com">danny@pablitostacos.com</a>
          </p>

          <>
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/935956192?h=9aac1f13c0"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
              />
            </div>
            {/* <p>
              <a href="https://vimeo.com/935956192">
                Pablito's Tacos Franchise Offering video
              </a>{" "}
              from <a href="https://vimeo.com/user16460207">Danny Rodriguez</a> on{" "}
              <a href="https://vimeo.com">Vimeo</a>.
            </p> */}
          </>


          <h2>Franchise Form</h2>

          <FormFranchise></FormFranchise>
          <h2>Summary</h2>
          <p>Join the Pablito's Tacos Family! The Future of Culinary Fusion is Here</p>
<p>Are you passionate about crafting exceptional culinary experiences? Do you want to be part of a rapidly expanding food revolution? Are you searching for a franchising opportunity that is steeped in quality, creativity, and customer satisfaction? If so, Pablito’s Tacos may be the perfect fit for you.</p>

<p><img src="https://afag.imgix.net/pablitos-tacos-burbank/insta-collage.png?w=1200&auto=format" alt="" /></p>
<p>In December 2018, the Pablito’s Tacos journey began. Founders Danny Rodriguez and Chef Flor Oropeza poured their love of Tijuana and Peruvian flavors into each hand-made tortilla and free guacamole serving, creating a unique gastronomic fusion that has earned them notable recognition. Now, they are ready to share their success by offering franchising opportunities.</p>
<p>Since its establishment, Pablito’s Tacos has grown rapidly, expanding to five locations in less than a year, and has earned a seat at the table of the top-ranking tacos in Los Angeles. It's not just our customers who love us, our accomplishments include being named a Top 10 Taco in LA by Eater LA, Top 20 Pastor in LA by LA TACO, and Top 5 Taco in San Fernando Valley by LA TACO. We've also been featured by the LA Times, Dine LA, Thrillist, and many more.</p>
<p>But we're not stopping there. We have ambitious expansion plans in the LA area, Antelope Valley, and we're also breaking new ground internationally in Canada and Peru.</p>
<p>We're looking for committed, enthusiastic entrepreneurs to join us on this exciting journey. As a Pablito's Tacos franchisee, you'll be part of a beloved brand, backed by a proven business model, and supported by a passionate team.</p>
<p>If you're ready to join a family that’s redefining the taco experience, one hand-made tortilla at a time, we'd love to hear from you. Embrace the opportunity to become a leader in the food industry and bring the explosive flavor of Pablito's Tacos to your community!</p>
<p>Contact us today for more information on how to start your Pablito's Tacos journey. Don't just make a living, make a difference – with Pablito's Tacos.</p>



          <div className="press-links-wrapper">
            <h2>Research Us</h2>
            <ul className="press-links">
              {pressLinks.map(({publication, text, url}) => {
                return(
                  <li>
                    <a href={url}>{publication}:</a>
                    <div className="text">{text}</div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </article>




    </div>
  )
}

function AboutUs () {
  return(
    <div className="about-us">
      <p>We are Pablito's Tacos, the hottest brand of tacos in Los Angles. What started as a small set up in a parking lot with 3 employees, has grown to over 25 employees, 2 trucks and 3 stands in just under a year. It was started by Danny Rodriguez and Chef Florencia "Flor" Oropeza in December 2018 out of the parking lot of Pablito's Kitchen. Flor who was the head chef of </p>
    </div>
  )
}



const pressLinks = [
  { publication: `Grubhub`,
  text: `GRUBHUB CHOOSES PABLITOS FOR NATIONWIDE CAMPAIGN`,
  url: "https://www.instagram.com/tv/CQjPxb2j603/"},

{ publication: `Eater`,
  text: `One of the Valley’s Most Famous Taco Spots Just Opened Big in Chinatown`,
  url: "https://la.eater.com/2022/5/18/23076643/am-intel-morning-news-los-angeles-pablitos-tacos-chinatown-world-food-court-opening"},
{ publication: `LA Times`,
  text: `This restaurant has a taco for every diet (restriction)`,
  url: "https://www.latimes.com/food/story/2022-08-29/keto-tacos-wedge-salad-nancy-silverton-jerusalem-chicken"},
{ publication: `First We Feast`,
  text: `Reggie Watts Beatboxes While Eating Peruvian-Style Tacos`,
  url: "https://www.youtube.com/watch?v=c7XfpPqKvzU"},
{ publication: `Instagram @foodbeast`,
  text: `Trying the Chile Relleno burrito`,
  url: "https://www.instagram.com/reel/CibN6e2pjYm/?igshid=YmMyMTA2M2Y%3D"},
{ publication: `ABC Eyewitness`,
  text: `Burbank's Pablito's Tacos adds a Peruvian taste to tacos that locals love`,
  url: "https://abc7.com/pablitos-tacos-burbank-food-peruvian-inspired/11769666/"},
{ publication: `Instagram @thejoe`,
  text: `Testing Pablito's tacos`,
  url: "https://www.instagram.com/reel/CjqcjyIpS-f/?igshid=YmMyMTA2M2Y%3D"},
{ publication: `Instagram @fluffcups`,
  text: `REVIEW @pablitostacos`,
  url: "https://www.instagram.com/reel/CnpP_Jejmhy/?igshid=YmMyMTA2M2Y%3D"},
{ publication: `LA Opinion`,
  text: `Tacos con fusión de sabor peruano y mexicano se vuelven un ‘hit’ entre los comensales`,
  url: "https://laopinion.com/2021/06/06/tacos-con-fusion-de-sabor-peruano-y-mexicano-se-vuelven-un-hit-entre-los-comensales/"},
{ publication: `Instagram @eatamerrica`,
  text: `PERFECT TACO SPOT ALERT!!!`,
  url: "https://www.instagram.com/p/CXwfdWMvEIq/?img_index=1"},

{ publication: `Eater`,
  text: `10 of LA’s Best Tijuana-Style Taco Spots, Mapped`,
  url: "https://la.eater.com/maps/tijuana-style-tacos-los-angeles-carne-asada-tortillas-adobada"},
{ publication: `CBS News`,
  text: `Major grocery chains rationing meat purchases to curb hoarding`,
  url: "https://www.cbsnews.com/news/meat-shortages-rationing-kroger-costco-grocery-chains-supermarkets/"},

{ publication: `L.A. Taco`,
  text: `THE L.A. TACO GUIDE TO THE 20 BEST AL PASTOR AND ADOBADA TACOS IN LOS ANGELES, MAPPED`,
  url: "https://www.lataco.com/best-al-pastor-adobada-la/"},

{ publication: `MSN hoodline`,
  text: `Pablito's Tacos brings tacos and more to North Hollywood`,
  url: "https://www.msn.com/en-us/news/us/pablitos-tacos-brings-tacos-and-more-to-north-hollywood/ar-BBV6V1p"},

{ publication: `Los Angeles Times`,
  text: `This new taco stand in Burbank is making Peruvian lomo saltado tacos`,
  url: "https://www.latimes.com/food/dailydish/la-fo-re-pablitos-20181227-story.html"},

{ publication: `Food&Wine`,
  text: `L.A.’s Summer of Tacos Can’t Stop, Won’t Stop`,
  url: "https://www.foodandwine.com/travel/restaurants/los-angles-taco-summer"},

{ publication: `L.A. Taco`,
  text: `FINALLY, THE L.A. TACO GUIDE TO THE BEST STREET TACOS IN THE SAN FERNANDO VALLEY`,
  url: "https://www.lataco.com/best-tacos-in-the-valley-2019/"},


{ publication: `Los Angeles Times`,
  text: `Newsletter: There’s no better place than Los Angeles to live the taco life`,
  url: "https://www.latimes.com/food/story/2019-10-12/los-originales-tacos-arabes-de-puebla-tasting-notes-newsletter-patricia-escarcega"},

  { publication: `@lafoodjunkie`,
    text: `EPIC QUESADILLA @pablitostacos 🔥🔥 this new PERUVIAN/TJ style taco stand is changing the game a bit🙌💯 @mrbiggsmenu #lafoodjunkie`,
    url: "https://www.instagram.com/lafoodjunkie/p/BtP4seWFPP4/"},

  { publication: `@pablitostacos`,
    text: `🚨NEW ITEM ALERT!!! 🤤 OUR LOMO SALTADO BURRITO🤤 ONLY AVAILABLE AT THE HOLLYWOOD LOCATION!!🚨.`,
    url: "https://instagram.com/p/Bwuue4hFK6N/"},

  { publication: `@latimesfood`,
    text: `We don’t clown around when it comes to #tacos. 🤡🌮🌮🌮`,
    url: "https://www.instagram.com/p/BzeXMrAA65y/?igshid=gyf6ey8jy38r"},

  { publication: `@goodeatzco`,
    text: `What do you do when it’s a little chilly outside? Dive into some huge extra CHEESY CARNE ASADA QUESADILLAS from @pablitostacos 🤤🧀🔥. Not to worry, the guac makes it healthy🤘🏻🥑
  🍴: (Carne Asada Quesadillas with Guac and Carne Asada Tacos ).`,
    url: "https://www.instagram.com/p/BtwPAiyhmUS/?igshid=ebsqupjkahkz"},

  { publication: `@mrbiggsmenu`,
    text: `EPIC QUESADILLA @pablitostacos 🔥🔥 this new PERUVIAN/TJ style taco stand is changing the game a bit🙌💯`,
    url: "https://www.instagram.com/p/Bs4jc7cgxRy/?igshid=t82rj4kt1y"},

  { publication: `@foodiemobbb`,
    text: `Would you try Asada Fries 🍟 Pablitos Style?!? (Added Chorizo) 🧐🧐🧐 If yes then you can get some from @pablitostacos !!! `,
    url: "https://www.instagram.com/p/By32G6-Fcga/?igshid=468h41zpf7ge"},

]
