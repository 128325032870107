import React from 'react'

import {Link} from 'react-router-dom'

export default function () {
  return(
    <div className="promotion-page">


      <div className="header">
        <h1>Hi</h1>
      </div>

      <form action=""></form>
    </div>
  )
}
