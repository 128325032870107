import React, {useEffect} from 'react'
// import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link, Redirect} from 'react-router-dom'
export default function () {
  useEffect(() => {
    window.document.getElementById('sorry').scrollIntoView(true)
  })
  return(
    <div className="catering-page">

      <div id="sorry"
        style={{
          height: '0px'
        }}
        ></div>
      <div className="top-banner" style={{background: 'black', color: 'white'}}>
        <h1 >We are Sorry</h1>
        {/* <p>Please order as much Taco Tuesday as you want here.</p> */}
      </div>
      <AutoMenu
        subMenuFilter={(subMenu) => {
          const tag = subMenu.tag
          if (tag === 'breakfast') {
            return false
          }

          return true
        }}
        ></AutoMenu>




    </div>
  )
}
